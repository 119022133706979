import React, { FC } from "react";
import Button from "../../../../components/Button";
import BackButton from "../../../../components/BackButton";

type Props = {
  page: number;
  disabled: boolean;
  next: () => void;
  prev: () => void;
};

const PageNav: FC<Props> = ({ page, next, prev, disabled }) => {
  return (
    <div className="flex justify-between gap-2 items-center">
      {page === 1 && (
        <BackButton title="Terug naar producten" link="/dashboard/products" />
      )}
      <div className="flex gap-2 self-end">
        {page !== 1 && (
          <Button
            onClick={() => prev()}
            size="md"
            className={`text-primary bg-white border-2 border-primary h-[40px] mt-0`}
            disabled={page === 1}
          >
            <span className="text-primary">Ga terug</span>
          </Button>
        )}

        <Button
          className="text-gray-light bg-primary h-[40px] mt-0"
          size="md"
          onClick={() => next()}
          disabled={disabled}
        >
          Volgende
        </Button>
      </div>
    </div>
  );
};

export default PageNav;
