import React, { FC, useState } from "react";
import convertToEuFormat from "../../../../../helpers/convertToEuFormat";
import Text from "../../../../../components/Text";
import { IoMdArrowDropleft, IoMdArrowDropright, IoMdEye } from "react-icons/io";
import Subtitle from "../../../../../components/Subtitle";
import EnergyLabel from "../../components/EnergyLabel";
import DownloadReport from "../pdf/EcoResultPDF";
import Api from "../../../../../components/ApiCard/Api";
import {
  FloorInsulation,
  HeatPump,
  RoofInsulation,
  Shower,
  SolarPanel,
  Ventilation,
  WallInsulation,
  WindowGlass,
} from "../../../../../assets/images/api/sustainability/sustainabilityImages";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal";
import PDFPreview from "../pdf/PDFPreview";
import { FaMoneyBillWave, FaPiggyBank } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";

type Props = {
  property: any;
  buildingPhoto?: string;
  map?: string;
};

const Result: FC<Props> = ({ property }) => {
  const overview = [
    {
      category: "Advies maatregelen",

      data: [
        {
          title: "Vloerisolatie",
          image: FloorInsulation,
          details: [
            {
              key: "Investering",
              value:
                "€" +
                convertToEuFormat(
                  property?.measures?.floor_insulation?.investment,
                ),
              unit: "",
            },
            {
              key: "Huidig",
              value: property?.measures?.floor_insulation?.before?.desc,
              unit: "",
            },
            {
              key: "Geadviseerd",
              value: property?.measures?.floor_insulation?.after?.desc,
              unit: "",
            },
            {
              key: "CO2 vermindering",
              value: convertToEuFormat(
                property?.measures?.floor_insulation?.co2_reduce + "kg",
              ),
              unit: "",
            },
            {
              key: "Besparing",
              value:
                "€" +
                convertToEuFormat(
                  property?.measures?.floor_insulation?.saving / 12,
                ) +
                " " +
                "p/m",
              unit: "",
            },
          ],
        },
        {
          title: "Dakisolatie",
          image: RoofInsulation,
          details: [
            {
              key: "Investering",
              value:
                "€" +
                convertToEuFormat(
                  property?.measures?.roof_insulation?.investment,
                ),
              unit: "",
            },
            {
              key: "Huidig",
              value: property?.measures?.roof_insulation?.before?.desc,
              unit: "",
            },
            {
              key: "Geadviseerd",
              value: property?.measures?.roof_insulation?.after?.desc,
              unit: "",
            },
            {
              key: "CO2 vermindering",
              value: convertToEuFormat(
                property?.measures?.roof_insulation?.co2_reduce + "kg",
              ),
              unit: "",
            },
            {
              key: "Besparing",
              value:
                "€" +
                convertToEuFormat(
                  property?.measures?.roof_insulation?.saving / 12,
                ) +
                " " +
                "p/m",
              unit: "",
            },
          ],
        },
        {
          title: "Gevelisolatie",
          image: WallInsulation,
          details: [
            {
              key: "Investering",
              value:
                "€" +
                convertToEuFormat(
                  property?.measures?.wall_insulation?.investment,
                ),
              unit: "",
            },
            {
              key: "Huidig",
              value: property?.measures?.wall_insulation?.before?.desc,
              unit: "",
            },
            {
              key: "Geadviseerd",
              value: property?.measures?.wall_insulation?.after?.desc,
              unit: "",
            },
            {
              key: "CO2 vermindering",
              value: convertToEuFormat(
                property?.measures?.wall_insulation?.co2_reduce + "kg",
              ),
              unit: "",
            },
            {
              key: "Besparing",
              value:
                "€" +
                convertToEuFormat(
                  property?.measures?.wall_insulation?.saving / 12,
                ) +
                " " +
                "p/m",
              unit: "",
            },
          ],
        },
        {
          title: "Glas woonkamer",
          image: WindowGlass,
          details: [
            {
              key: "Investering",
              value:
                "€" +
                convertToEuFormat(
                  property?.measures?.living_room_windows?.investment,
                ),
              unit: "",
            },
            {
              key: "Huidig",
              value: property?.measures?.living_room_windows?.before?.desc,
              unit: "",
            },
            {
              key: "Geadviseerd",
              value: property?.measures?.living_room_windows?.after?.desc,
              unit: "",
            },
            {
              key: "CO2 vermindering",
              value: convertToEuFormat(
                property?.measures?.living_room_windows?.co2_reduce + "kg",
              ),
              unit: "",
            },
            {
              key: "Besparing",
              value:
                "€" +
                convertToEuFormat(
                  property?.measures?.living_room_windows?.saving / 12,
                ) +
                " " +
                "p/m",
              unit: "",
            },
          ],
        },
        {
          title: "Glas slaapkamers",
          image: WindowGlass,
          details: [
            {
              key: "Investering",
              value:
                "€" +
                convertToEuFormat(
                  property?.measures?.bedroom_windows?.investment,
                ),
              unit: "",
            },
            {
              key: "Huidig",
              value: property?.measures?.bedroom_windows?.before?.desc,
              unit: "",
            },
            {
              key: "Geadviseerd",
              value: property?.measures?.bedroom_windows?.after?.desc,
              unit: "",
            },
            {
              key: "CO2 vermindering",
              value: convertToEuFormat(
                property?.measures?.bedroom_windows?.co2_reduce + "kg",
              ),
              unit: "",
            },
            {
              key: "Besparing",
              value:
                "€" +
                convertToEuFormat(
                  property?.measures?.bedroom_windows?.saving / 12,
                ) +
                " " +
                "p/m",
              unit: "",
            },
          ],
        },
        {
          title: "Aantal zonnepanelen",
          image: SolarPanel,
          details: [
            {
              key: "Investering",
              value:
                "€" +
                convertToEuFormat(property?.measures?.solar_panels?.investment),
              unit: "",
            },
            {
              key: "Huidig",
              value: property?.measures?.solar_panels?.before?.desc,
              unit: "",
            },
            {
              key: "Geadviseerd",
              value: property?.measures?.solar_panels?.after?.desc,
              unit: "",
            },
            {
              key: "CO2 vermindering",
              value: convertToEuFormat(
                property?.measures?.solar_panels?.co2_reduce + "kg",
              ),
              unit: "",
            },
            {
              key: "Besparing",
              value:
                "€" +
                convertToEuFormat(property?.measures?.solar_panels?.saving) +
                " " +
                "p/m",
              unit: "",
            },
          ],
        },
        {
          title: "CV-ketel of warmtepomp",
          image: HeatPump,
          details: [
            {
              key: "Investering",
              value:
                "€" +
                convertToEuFormat(property?.measures?.instalation?.investment),
              unit: "",
            },
            {
              key: "Huidig",
              value: property?.measures?.instalation?.before?.desc,
              unit: "",
            },
            {
              key: "Geadviseerd",
              value: property?.measures?.instalation?.after?.desc,
              unit: "",
            },
            {
              key: "CO2 vermindering",
              value:
                convertToEuFormat(property?.measures?.instalation?.co2_reduce) +
                "kg",
              unit: "",
            },
            {
              key: "Besparing",
              value:
                "€" +
                convertToEuFormat(
                  property?.measures?.instalation?.saving / 12,
                ) +
                " " +
                "p/m",
              unit: "",
            },
          ],
        },
        {
          title: "Huidige douche WTW",
          image: Shower,
          details: [
            {
              key: "Investering",
              value:
                "€" + convertToEuFormat(property?.measures?.shower?.investment),
              unit: "",
            },
            {
              key: "Huidig",
              value: property?.measures?.shower?.before?.desc,
              unit: "",
            },
            {
              key: "Geadviseerd",
              value: property?.measures?.shower?.after?.desc,
              unit: "",
            },
            {
              key: "CO2 vermindering",
              value:
                convertToEuFormat(property?.measures?.shower?.co2_reduce) +
                "kg",
              unit: "",
            },
            {
              key: "Besparing",
              value:
                "€" +
                convertToEuFormat(property?.measures?.shower?.saving / 12) +
                " " +
                "p/m",
              unit: "",
            },
          ],
        },
        {
          title: "Ventilatie",
          image: Ventilation,
          details: [
            {
              key: "Investering",
              value:
                "€" +
                convertToEuFormat(property?.measures?.ventilation?.investment),
              unit: "",
            },
            {
              key: "Huidig",
              value: property?.measures?.ventilation?.before?.desc,
              unit: "",
            },
            {
              key: "Geadviseerd",
              value: property?.measures?.ventilation?.after?.desc,
              unit: "",
            },
            {
              key: "CO2 vermindering",
              value:
                convertToEuFormat(property?.measures?.ventilation?.co2_reduce) +
                "kg",
              unit: "",
            },
            {
              key: "Besparing",
              value:
                "€" +
                convertToEuFormat(
                  property?.measures?.ventilation?.saving / 12,
                ) +
                " " +
                "p/m",
              unit: "",
            },
          ],
        },
      ],
    },
    {
      category: "Woningdetails",
      data: [
        {
          title: "Woningdetails",
          details: [
            {
              key: "Bouwjaar",
              value: property?.building?.build_year,
              unit: "",
            },
            {
              key: "Woonoppervlakte",
              value: convertToEuFormat(property?.building?.inner_surface),
              unit: "m2",
            },
          ],
        },
      ],
    },
    {
      category: "Financiën",
      data: [
        {
          title: "Financiën",
          details: [
            {
              key: "Besparing per maand",
              value:
                "€" + convertToEuFormat(property?.financial?.savings_monthly),
              unit: "",
            },
            {
              key: "Investering",
              value:
                "€" + convertToEuFormat(property?.financial?.total_investment),
              unit: "",
            },
          ],
        },
      ],
    },
    {
      category: "Huidig energielabel",
      data: [
        {
          title: "Huidig energielabel",
          details: [
            {
              key: "Ingeschat energielabel",
              value: <EnergyLabel label={property?.label?.current} />,
              unit: "",
            },
            {
              key: "Definitief energielabel",
              value: (
                <EnergyLabel label={property?.provided_label?.definitive} />
              ),
              unit: "",
            },
            {
              key: "Type label",
              value: property?.provided_label?.definitive_type,
              unit: "",
            },
            {
              key: "Geldig tot en met",
              value: property?.provided_label?.definitive_validity,
              unit: "",
            },
          ],
        },
      ],
    },
    {
      category: "Energietarieven",
      data: [
        {
          title: "Energietarieven",
          details: [
            {
              key: "Gastarief",
              value:
                "€" +
                convertToEuFormat(property?.energy_prices?.variable_gas_price),
              unit: "",
            },
            {
              key: "Energietarief",
              value:
                "€" +
                convertToEuFormat(
                  property?.energy_prices?.variable_electricity_price,
                ),
              unit: "",
            },
            {
              key: "Wattpiek opbrengst zonnepaneel per m2",
              value: convertToEuFormat(
                property?.energy_prices?.solarpanel_watt_peak_per_m2,
              ),
              unit: "",
            },
          ],
        },
      ],
    },
    {
      category: "CO2 Uitstoot",
      data: [
        {
          title: "CO2 Uitstoot",
          details: [
            {
              key: "Huidig ",
              value: convertToEuFormat(property?.CO2?.current),
              unit: "kg",
            },
            {
              key: "Potentieel",
              value: convertToEuFormat(property?.CO2?.potential),
              unit: "kg",
            },
          ],
        },
      ],
    },
    {
      category: "Energiebesparing",
      data: [
        {
          title: "Huidig (ingeschat) verbruik",
          details: [
            {
              key: "Gas",
              value: convertToEuFormat(property?.usage?.gas),
              unit: "m3",
            },
            {
              key: "Electriciteit",
              value: convertToEuFormat(property?.usage?.energy),
              unit: "kWh",
            },
          ],
        },
        {
          title: "Toekomstig verbruik",
          details: [
            {
              key: "Gas",
              value: convertToEuFormat(property?.usage_potential?.gas),
              unit: "m3",
            },
            {
              key: "Electriciteit",
              value: convertToEuFormat(property?.usage_potential?.energy),
              unit: "kWh",
            },
          ],
        },
      ],
    },
  ];
  const [activeResult, setActiveResult] = useState<any>(overview[0]);
  const [activeIndex, setActiveIndex] = useState(0);
  const popupDetails = (
    <div
      className={`md:shadow-[0px_2px_8px_0px_#00000026] md:rounded-md ${
        Object.keys(activeResult).length > 0
          ? "w-full h-full max-h-[600px] p-4 overflow-auto"
          : "w-0"
      } `}
    >
      {Object.keys(activeResult).length > 0 && (
        <>
          {activeResult?.data?.map((res: any, index: number) => (
            <div key={index} className="mt-6">
              <div className="flex gap-2 items-center">
                {res.image && (
                  <img
                    src={res.image}
                    alt={res.title}
                    className="w-[32px] h-[32px]"
                  />
                )}
                <Subtitle className="text-base">{res.title}</Subtitle>
              </div>
              {res.details.map((res: any, index: number) => (
                <div className="flex justify-between mt-4" key={index}>
                  <Text className="text-gray-light">{res.key}</Text>
                  <Text className="text-gray-dark font-medium">
                    {res.value}
                  </Text>
                </div>
              ))}
            </div>
          ))}
        </>
      )}
    </div>
  );

  return (
    <div className="md:flex md:gap-8 md:justify-between">
      <div className="flex flex-col gap-4">
        {overview.map((res, idx) => (
          <>
            <div
              className={`shadow-[0px_2px_8px_0px_#00000026] rounded-md flex justify-between items-center w-full min-w-[300px] h-[72px] p-3 ${
                idx === activeIndex ? "text-primary" : "text-gray-light"
              }`}
              key={idx}
              onClick={() => {
                setActiveResult(res);
                setActiveIndex(idx);
              }}
            >
              <Text className={`font-semibold text-base`}>{res.category}</Text>
              {idx === activeIndex ? (
                <IoMdArrowDropleft />
              ) : (
                <IoMdArrowDropright />
              )}
            </div>
            <div className={`md:hidden`}>
              {idx === activeIndex && popupDetails}
            </div>
          </>
        ))}
      </div>
      <div className="hidden md:block w-full">{popupDetails}</div>
    </div>
  );
};

export default Result;

export const ResultSummary: FC<Props> = ({ property, buildingPhoto, map }) => {
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);

  const desc = [
    {
      icon: <FaMoneyBillWave size={24} />,
      title: "Totale investering",
      result: "€" + convertToEuFormat(property?.financial?.total_investment),
    },
    {
      icon: <FaHome size={24} />,
      title: "Potentiële ecowaarde",
      result: "€" + convertToEuFormat(property?.financial?.eco_value),
    },
    {
      icon: <FaPiggyBank size={24} />,
      title: "Maandelijkse besparing",
      result: "€" + convertToEuFormat(property?.financial?.savings_monthly),
    },
    {
      icon: null,
      title: "Energielabel",
      result: (
        <>
          <EnergyLabel label={property?.label?.current} /> {">"}{" "}
          <EnergyLabel label={property?.label?.potential} />
        </>
      ),
    },
  ];

  return (
    <>
      <div className="bg-white shadow-[0px_2px_8px_0px_#00000026] rounded-md md:flex md:flex-wrap justify-between p-4 md:p-6 absolute lg:top-[320px] lg:left-[100px] lg:w-4/5 md:top-[230px] md:left-[50px] md:w-[630px] top-[200px] left-[40px] w-[300px] grid grid-cols-2 gap-2">
        {desc.map(({ icon, title, result }, index) => (
          <div
            key={index}
            className="flex flex-row md:flex-col items-center gap-2"
          >
            <div className="md:hidden">{icon}</div>
            <Text className="hidden md:block">{title}</Text>
            <Subtitle className="flex text-base md:text-xl">{result}</Subtitle>
          </div>
        ))}
      </div>
      <div className="flex absolute lg:top-[390px] lg:left-[370px] md:top-[300px] md:left-[160px] top-[300px] left-[100px] gap-2">
        <Button
          type="button"
          onClick={() => setIsPreviewModalVisible(true)}
          className="ml-2 bg-white text-primary border border-primary flex items-center"
        >
          <IoMdEye size={20} />{" "}
          <span className="hidden md:inline ml-2">Preview PDF</span>
        </Button>
        <DownloadReport
          buildingPhoto={buildingPhoto || ""}
          data={property}
          map={map || ""}
        />
      </div>
      <Modal
        trigger={isPreviewModalVisible}
        setTrigger={setIsPreviewModalVisible}
      >
        <PDFPreview
          data={property}
          buildingPhoto={buildingPhoto || ""}
          map={map || ""}
        />
      </Modal>
    </>
  );
};

export const ExplorOtherApis = () => {
  return (
    <div className="flex flex-col gap-8">
      <Subtitle className="">Ontdek meer API's</Subtitle>
      <div className="flex w-full flex-wrap gap-8">
        <Api activeCategory={"Verduurzamen"} search={""} />
      </div>
    </div>
  );
};
