import { FC } from "react";

import Text from "../../../../../components/Text";

import Subtitle from "../../../../../components/Subtitle";
// import PDFPreview from "../pdf/PDFPreview";

// import DownloadReport from "../pdf/EnergyLabelPDF";

import { FaHome } from "react-icons/fa";

import EnergyLabel from "../../../../../components/apiForms/EnergyLabel";

type Props = {
  property: any;

  buildingPhoto?: string;

  map?: string;
};

export const ResultSummary: FC<Props> = ({ property }) => {
  const desc = [
    {
      icon: <FaHome size={24} />,

      title: "Ingeschat energielabel",

      result: <EnergyLabel label={property?.current_estimated_energy_label} />,
    },

    {
      icon: null,

      title: "Definitief energielabel",

      result: <EnergyLabel label={property?.definitive_energy_label} />,
    },
  ];

  return (
    <>
      <div className="bg-white shadow-[0px_2px_8px_0px_#00000026] rounded-md md:flex md:flex-wrap justify-between p-4 md:p-6 absolute lg:top-[320px] lg:left-[250px] lg:w-[496px] md:top-[230px] md:left-[50px] md:w-[409px] top-[200px] left-[40px] w-[300px] grid grid-cols-2 gap-2">
        {desc.map(({ icon, title, result }, index) => (
          <div
            key={index}
            className="flex flex-row md:flex-col items-center gap-2"
          >
            <Text className="hidden md:block">{title}</Text>

            <Subtitle className="flex text-base md:text-xl">{result}</Subtitle>
          </div>
        ))}
      </div>

      {/* <div className="flex absolute lg:top-[390px] lg:left-[370px] md:top-[300px] md:left-[160px] top-[300px] left-[100px] gap-2">
        <Button
          type="button"
          onClick={() => setIsPreviewModalVisible(true)}
          className="ml-2 bg-white text-primary border border-primary flex items-center"
        >
          <IoMdEye size={20} />{" "}
          <span className="hidden md:inline ml-2">Preview PDF</span>
        </Button>

        <DownloadReport
          buildingPhoto={buildingPhoto || ""}
          data={property}
          map={map || ""}
        />
      </div> */}

      {/* <Modal
        trigger={isPreviewModalVisible}
        setTrigger={setIsPreviewModalVisible}
      >
        <PDFPreview
          data={property}
          buildingPhoto={buildingPhoto || ""}
          map={map || ""}
        />
      </Modal> */}
    </>
  );
};
