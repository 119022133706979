import { FC, useState } from "react";

import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";

import Text from "../../../../../components/Text";

import Subtitle from "../../../../../components/Subtitle";

import convertToEuFormat from "../../../../../helpers/convertToEuFormat";

import {
  FloorInsulation,
  HeatPump,
  RoofInsulation,
  Shower,
  Ventilation,
  WallInsulation,
  WindowGlass,
} from "../../../../../assets/images/api/sustainability/sustainabilityImages";

import {
  MdOutlineGasMeter,
  MdOutlineRealEstateAgent,
  MdOutlineSolarPower,
  MdRecycling,
} from "react-icons/md";

import { RiAwardLine } from "react-icons/ri";

import { getMeasureDescription } from "../../../../../helpers/measureHelpers";

type Props = {
  property: any;

  buildingPhoto?: string;

  map?: string;
};

const Result: FC<Props> = ({ property }) => {
  const overview = [
    {
      category: "Woningdetails",

      icon: <MdOutlineRealEstateAgent className="w-8 h-8" />,

      data: [
        {
          title: "Woningdetails",

          details: [
            {
              key: "Woningtype",

              value: property.house_type,

              unit: "",
            },

            {
              key: "Bouwjaar",

              value: property.build_year,

              unit: "",
            },

            {
              key: "Woonoppervlakte",

              value: convertToEuFormat(property.inner_surface_area),

              unit: "m²",
            },
          ],
        },
      ],
    },

    {
      category: "Verduurzaminggegevens",

      icon: <MdRecycling className="w-8 h-8" />,

      data: [
        {
          title: "",

          image: HeatPump,

          details: [
            {
              key: "CV-ketel of warmtepomp",

              value: getMeasureDescription(
                "installation",
                property.installation,
              ),

              unit: "",
            },
          ],
        },

        {
          title: "",

          image: WallInsulation,

          details: [
            {
              key: "Gevelisolatie",

              value: getMeasureDescription(
                "wall_insulation",
                property.wall_insulation,
              ),

              unit: "",
            },
          ],
        },

        {
          title: "",

          image: RoofInsulation,

          details: [
            {
              key: "Dakisolatie",

              value: getMeasureDescription(
                "roof_insulation",
                property.roof_insulation,
              ),

              unit: "",
            },
          ],
        },

        {
          title: "",

          image: FloorInsulation,

          details: [
            {
              key: "Vloerisolatie",

              value: getMeasureDescription(
                "floor_insulation",
                property.floor_insulation,
              ),

              unit: "",
            },
          ],
        },

        {
          title: "",

          image: WindowGlass,

          details: [
            {
              key: "Glas woonkamer",

              value: getMeasureDescription(
                "living_room_windows",
                property.living_room_windows,
              ),

              unit: "",
            },

            {
              key: "Glas slaapkamers",

              value: getMeasureDescription(
                "bedroom_windows",
                property.bedroom_windows,
              ),

              unit: "",
            },
          ],
        },

        {
          title: "",

          image: Shower,

          details: [
            {
              key: "Huidige douche WTW",

              value: getMeasureDescription("shower", property.shower),

              unit: "",
            },
          ],
        },

        {
          title: "",

          image: Ventilation,

          details: [
            {
              key: "Ventilatie",

              value: getMeasureDescription("ventilation", property.ventilation),

              unit: "",
            },
          ],
        },
      ],
    },

    {
      category: "Energiegebruik",

      icon: <MdOutlineSolarPower className="w-8 h-8" />,

      data: [
        {
          title: "Energiegebruik",

          details: [
            {
              key: "Wattpiek opbrengst zonnepaneel per m2",

              value: convertToEuFormat(property.solarpanel_watt_peak),

              unit: "",
            },

            {
              key: "CO2",

              value: convertToEuFormat(property.CO2),

              unit: "kg",
            },

            {
              key: "BENG2 score",

              value: convertToEuFormat(property.current_estimated_BENG2_score),

              unit: "",
            },
          ],
        },
      ],
    },

    {
      category: "Verbruik",

      icon: <MdOutlineGasMeter className="w-8 h-8" />,

      data: [
        {
          title: "Verbruik",

          details: [
            {
              key: "Gasverbruik",

              value: convertToEuFormat(property.estimated_gas_usage),

              unit: "m3",
            },

            {
              key: "Elektriciteitsverbruik",

              value: convertToEuFormat(property.estimated_energy_usage),

              unit: "kWh",
            },
          ],
        },
      ],
    },

    {
      category: "Certificeringsdetails",

      icon: <RiAwardLine className="w-8 h-8" />,

      data: [
        {
          title: "Certificeringsdetails",

          details: [
            {
              key: "Energielabel norm",

              value: property.definitive_energy_label_type,

              unit: "",
            },

            {
              key: "Geldig t/m",

              value: property.definitive_energy_label_validity_date,

              unit: "",
            },

            {
              key: "Ingeschat energielabel",

              value: property.current_estimated_energy_label,

              unit: "",
            },

            {
              key: "Definitief energielabel",

              value: property.definitive_energy_label,

              unit: "",
            },
          ],
        },
      ],
    },
  ];

  const [activeResult, setActiveResult] = useState<any>(overview[0]);

  const [activeIndex, setActiveIndex] = useState(0);

  const popupDetails = (
    <div
      className={`md:shadow-[0px_2px_8px_0px_#00000026] md:rounded-md ${
        Object.keys(activeResult).length > 0
          ? "w-full h-full max-h-[600px] p-4 overflow-auto"
          : "w-0"
      } `}
    >
      {Object.keys(activeResult).length > 0 && (
        <>
          {activeResult?.data?.map((res: any, index: number) => (
            <div key={index} className="mt-6">
              <div className="flex gap-2 items-center">
                {res.image && (
                  <img
                    src={res.image}
                    alt={res.title}
                    className="w-[32px] h-[32px]"
                  />
                )}

                <Subtitle className="text-base">{res.title}</Subtitle>
              </div>

              {res.details.map((detail: any, idx: number) => (
                <div className="flex justify-between mt-4" key={idx}>
                  <Text className="text-gray-light">{detail.key}</Text>

                  <Text className="text-gray-dark font-medium">
                    {detail.value}

                    {detail.unit && ` ${detail.unit}`}
                  </Text>
                </div>
              ))}
            </div>
          ))}
        </>
      )}
    </div>
  );

  return (
    <div className="md:flex md:gap-8 md:justify-between">
      <div className="flex flex-col gap-4">
        {overview.map((res, idx) => (
          <>
            <div
              className={`shadow-[0px_2px_8px_0px_#00000026] rounded-md flex justify-between items-center w-full min-w-[300px] h-[72px] p-3`}
              key={idx}
              onClick={() => {
                setActiveResult(res);

                setActiveIndex(idx);
              }}
            >
              <div className={`flex items-center gap-3`}>
                <div
                  className={`${
                    idx === activeIndex ? "text-[#FFC694]" : "text-[#FFC694]/50"
                  }`}
                >
                  {res.icon}
                </div>

                <Text
                  className={`font-semibold text-base ${
                    idx === activeIndex ? "text-gray-dark" : "text-gray-light"
                  }`}
                >
                  {res.category}
                </Text>
              </div>

              {idx === activeIndex ? (
                <IoMdArrowDropleft className="w-6 h-6 text-primary" />
              ) : (
                <IoMdArrowDropright className="w-6 h-6 text-gray-light" />
              )}
            </div>

            <div className={`md:hidden`}>
              {idx === activeIndex && popupDetails}
            </div>
          </>
        ))}
      </div>

      <div className="hidden md:block w-full">{popupDetails}</div>
    </div>
  );
};

export default Result;
