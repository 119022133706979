import React, { FC } from "react";
import { FaCheck } from "react-icons/fa6";
import Text from "../../../../components/Text";

type Props = {
  image: string;
  page: number;
  progress: string[];
};

const ProgressSection: FC<Props> = ({ image, page, progress }) => {
  const desktopProgress = (
    <div className="relative lg:flex flex-col justify-between rounded-2xl bg-secondary w-[279px] px-4 py-10 hidden">
      <div className=" flex flex-col h-full gap-12 justify-between">
        {progress.map((bar, index) => (
          <div className="relative">
            <div className="flex items-center gap-4 ">
              <div
                className={`flex rounded-full w-[57px] h-[57px] items-center justify-center ${
                  Math.floor(page) >= index + 1
                    ? "bg-primary"
                    : "bg-secondary border border-gray-light text-gray-light"
                }`}
              >
                {Math.floor(page) > index + 1 ? <FaCheck /> : index + 1}
              </div>
              <Text
                className={`${
                  page >= index + 1 ? "text-gray-dark " : "text-gray-light"
                } `}
              >
                {bar}
              </Text>
            </div>
            {index < progress.length - 1 && (
              <div className="absolute h-full w-px border-dashed border border-gray-light left-7" />
            )}
          </div>
        ))}
      </div>
      <div className="w-[343px] h-[334px]">
        <img src={image} alt="api" />
      </div>
    </div>
  );

  const mobileProgress = (
    <div className="flex justify-between gap-2 w-full absolute top-0 lg:hidden">
      {progress.map((bar, index) => (
        <div
          className={`w-full h-1 ${
            Math.floor(page) >= index + 1 ? "bg-primary" : "bg-[#E0E0E0]"
          } rounded-md`}
        ></div>
      ))}
    </div>
  );

  return (
    <div>
      {desktopProgress}
      {mobileProgress}
    </div>
  );
};

export default ProgressSection;
