import { FC, useCallback } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import Button from "./Button";

const BackButton: FC<{ title?: string; link?: string; color?: string }> = ({
  title,
  link,
  color = "text-black",
}) => {
  const history = useHistory();
  const handleBack = useCallback(() => {
    if (link) history.push(link);
    else history.goBack();
  }, [history, link]);

  return (
    <Button
      onClick={handleBack}
      className={`self-start mt-0 p-0 hover:underline hover:underline-offset-4 hover:decoration-blue-400 hover:decoration-2 hover:text-blue-400 ${color}`}
    >
      <IoMdArrowBack className={`h-6 w-6`} />
      <span className="text-base">{title}</span>
    </Button>
  );
};

export default BackButton;
