import { useFormContext } from "../../components/FormContext";
import { identifyPropertyField } from "../data";
import ReturnInputField from "../../../../../components/ReturnInputField";
const IdentifyProperty = () => {
  const { formValues, setFormValues } = useFormContext();

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-semibold mb-4">
          Welke woning wil je beoordelen?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {identifyPropertyField.map((field, index) => (
            <ReturnInputField
              key={index}
              label={field.label}
              type={field.type}
              name={field.name}
              placeholder={field.placeholder}
              options={field.options}
              onChange={(e) => {
                "target" in e &&
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  });
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default IdentifyProperty;
