import React, { ReactNode } from "react";
import ApiFormContainer from "../../components/ApiFormContainer";
import { EnergyLabel } from "../../../../../assets/images/api/APIimages";

const progress = ["Woning identificatie", "Huidige status", "Resultaat"];

const EnergyLabelContainer: React.FC<{
  children: ReactNode;
  page: number;
}> = ({ children, page }) => {
  return (
    <ApiFormContainer
      page={page}
      title="Een geschatte huidige energielabel genereren"
      subtitle="Bepaal het geschatte huidige of potentiële energielabel volgens de NTA 8800-norm gebaseerd op informatie over de huidige of verwachte situatie van het pand."
      progress={progress}
      resultSelector={(state) => state.energyLabel.result}
      link="https://docs.altum.ai/apis/energy-label-api"
      image={EnergyLabel}
    >
      {children}
    </ApiFormContainer>
  );
};

export default EnergyLabelContainer;
