import { FC, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { useFormContext } from "../../components/FormContext";
import PageNav from "../../components/PageNav";
import IdentifyProperty from "../components/IdentifyProperty";
import PropertyStatus from "../components/PropertyStatus";
import { postEnergyLabel } from "../../../../../redux/actions/energyLabelActions";
import { Redirect } from "react-router-dom";
import IsMeasureImplemented from "../../sustainability/components/IsMeasureImplemented";
import MeasureImplemented from "../../sustainability/components/MeasureImplemented";

type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const EnergyLabelForm: FC<Props> = ({ page, setPage }) => {
  const { formValues, validate, fetchAddressData, setPostalAddress } =
    useFormContext();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.energyLabel);
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);
  const [isMeasure, setIsMeasure] = useState<string | number>("no");

  const validatePostCode = useCallback(() => {
    if (!formValues) return false;
    const postCodeErrors = validate(
      {
        post_code: formValues.post_code,
        house_number: formValues.house_number,
      },
      {
        post_code: {
          minLength: 6,
          maxLength: 6,
          regex: /^[0-9]{4}[A-Za-z]{2}$/i,
          required: true,
        },
        house_number: {
          minLength: 1,
          maxLength: 6,
          required: true,
        },
      },
    );

    return !postCodeErrors.post_code && !postCodeErrors.house_number;
  }, [formValues, validate]);

  const handleFormSubmit = useCallback(() => {
    const updatedData = {
      post_code: formValues.post_code?.split(" ").join("").toUpperCase() || "",
      house_number: formValues.house_number,
      house_type: formValues.house_type,
      inner_surface_area: formValues.inner_surface_area,
      build_year: formValues.build_year,
    };

    apiKey && dispatch(postEnergyLabel({ formData: updatedData, apiKey }));
  }, [apiKey, dispatch, formValues]);

  useEffect(() => {
    if (formValues.post_code && formValues.house_number) {
      setPostalAddress(
        `${formValues.post_code}-${formValues.house_number}-${
          formValues.addition ? formValues.addition : ""
        }`,
      );
    }
  }, [formValues, setPostalAddress]);

  const next = useCallback(() => {
    if (page === 1 && !validatePostCode()) return;
    if (page === 1 && validatePostCode()) fetchAddressData();
    if (page === 2 && isMeasure === "no") handleFormSubmit();
    if (page === 2.5) handleFormSubmit();
    setPage((prevPage) =>
      isMeasure === "yes" && prevPage === 2
        ? prevPage + 0.5
        : Math.floor(prevPage) + 1,
    );
  }, [
    page,
    validatePostCode,
    fetchAddressData,
    handleFormSubmit,
    setPage,
    isMeasure,
  ]);

  if (loading) {
    return <Redirect to="/energylabel-result" />;
  }

  return (
    <>
      {page === 1 && <IdentifyProperty />}
      {page === 2 && (
        <>
          <PropertyStatus />
          <IsMeasureImplemented
            isMeasure={isMeasure}
            setIsMeasure={setIsMeasure}
          />
        </>
      )}
      {page > 2 && page < 3 && isMeasure === "yes" && <MeasureImplemented />}
      {page < 3 && (
        <PageNav
          page={page}
          disabled={Object.keys(formValues).length === 0}
          next={next}
          prev={() => setPage((p) => Math.max(p - 1, 1))}
        />
      )}
    </>
  );
};

export default EnergyLabelForm;
