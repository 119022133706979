import { InputProps } from "../../../../@types";

export const identifyPropertyField: InputProps[] = [
  {
    label: "Postcode*",
    name: "post_code",
    type: "text",
    placeholder: "1234AB",
    tooltip: "",
  },
  {
    label: "Huisnummer*",
    name: "house_number",
    type: "text",
    placeholder: "10",
    tooltip: "",
  },
  {
    label: "Huisnummer toevoeging",
    name: "addition",
    type: "text",
    placeholder: "A",
    tooltip: "",
  },
  {
    label: "Woningtype",
    name: "house_type",
    type: "select",
    placeholder: "Selecteer woningtype",
    tooltip: "Het woningtype is van invloed op de berekeningen",
    options: [
      { label: "2-onder-1-kapwoning", value: 1 },
      { label: "hoekwoning", value: 2 },
      { label: "tussenwoning", value: 3 },
      { label: "vrijstaand", value: 4 },
      { label: "appartment", value: 5 },
    ],
  },
  {
    label: "Bouwjaar",
    name: "build_year",
    type: "number",
    placeholder: "2023",
    tooltip: "Voer het originele bouwjaar in van de woning",
  },
  {
    label: "Totale woonoppervlakte (m2)",
    name: "inner_surface_area",
    type: "number",
    placeholder: "100",
    tooltip:
      "Het oppervlak van de woning heeft invloed op de energetische berekeningen",
  },
];

export const propertyStatus = [
  {
    label: "Aantal bewoners",
    name: "inhabitants",
    type: "number",
    placeholder: "2",
    tooltip: "Het aantal inwoners heeft invloed op de berekeningen",
  },
];

export const energyLabelEndpoint = {
  title: "Generate an estimated current energy label",
  description:
    "Determine the estimated current or potential energy label according to the NTA 8800 standard based on information about the current or expected situation of the property.",
  method: "POST",
  url: "https://api.altum.ai/energylabel",
  headers: [
    {
      name: "x-api-key",
      type: "string",
      required: true,
      description:
        "Altum API key. Create one via Mopsus (https://mopsus.altum.ai)",
    },
    {
      name: "Content-Type",
      type: "string",
      required: false,
      description: "application/json",
    },
  ],
  responses: [
    { status: 200, description: "Successful response" },
    { status: 422, description: "Wrong input format" },
    { status: 400, description: "Failed response" },
    { status: 301, description: "Apartment error" },
    { status: 303, description: "House type error" },
    { status: 500, description: "Service is unavailable" },
    { status: 404, description: "When BagId is used instead of Address" },
    { status: 401, description: "No access" },
    { status: 403, description: "Forbidden" },
  ],
};
