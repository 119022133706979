import React from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";
import styles from "./styles";
import altumLogo from "../../../../../assets/images/Logo-AltumAI.png";
import {
  SolarPanel,
  FloorInsulation,
  WindowGlass,
  WallInsulation,
  HeatPump,
  Shower,
  Ventilation,
} from "../../../../../assets/images/api/sustainability/sustainabilityImages";
import convertToEuFormat from "../../../../../helpers/convertToEuFormat";

interface CompleteOverzichtPageProps {
  data: any;
}

interface RecommendationItemProps {
  icon: string;
  title: string;
  description: string;
  co2Reduction: number;
  monthlySavings: number;
  installationCost: number;
  paybackPeriod: number;
  additionalBenefits: string[];
}

const RecommendationItem: React.FC<RecommendationItemProps> = ({
  icon,
  title,
  description,
  co2Reduction,
  monthlySavings,
  installationCost,
  paybackPeriod,
  additionalBenefits,
}) => (
  <View style={styles.recommendationContainer}>
    <View style={styles.recommendationHeader}>
      <Image src={icon} style={styles.recommendationIcon} />
      <Text style={styles.recommendationTitle}>{title}</Text>
    </View>

    <Text style={styles.recommendationText}>
      {description}. Hier is waarom:
    </Text>

    <View style={styles.recommendationPoints}>
      <Text style={styles.recommendationPoint}>
        • Hergebruikt warmte van douchewater om koud water voor te verwarmen
      </Text>
      <Text style={styles.recommendationPoint}>
        • Verlaging van CO2-uitstoot met {convertToEuFormat(co2Reduction)} kg
        per jaar
      </Text>
      <Text style={styles.recommendationPoint}>
        • Maandelijkse besparing van €{convertToEuFormat(monthlySavings / 12)}
      </Text>

      <Text style={styles.recommendationText}>Kostenoverzicht:</Text>
      <Text style={styles.recommendationText}>
        Installatiekosten: €{convertToEuFormat(installationCost)}
      </Text>
      <Text style={styles.recommendationText}>
        Terugverdientijd: Ongeveer {paybackPeriod} jaar
      </Text>
      <Text style={styles.recommendationText}>Extra voordelen:</Text>
      {additionalBenefits.map((benefit, index) => (
        <Text key={index} style={styles.recommendationPoint}>
          • {benefit}
        </Text>
      ))}
    </View>
  </View>
);

const getIconForMeasure = (measure: string) => {
  switch (measure) {
    case "solar_panels":
      return SolarPanel;
    case "floor_insulation":
      return FloorInsulation;
    case "living_room_windows":
      return WindowGlass;
    case "bedroom_windows":
      return WindowGlass;
    case "wall_insulation":
      return WallInsulation;
    case "instalation":
      return HeatPump;
    case "shower":
      return Shower;
    case "ventilation":
      return Ventilation;
    default:
      return "";
  }
};

const getAdditionalBenefits = (measure: string) => {
  switch (measure) {
    case "solar_panels":
      return [
        "Verhoogde energie-onafhankelijkheid",
        "Mogelijkheid om overtollige energie terug te leveren aan het net",
        "Minimaal onderhoud vereist",
      ];
    case "floor_insulation":
      return [
        "Verbeterd comfort met constantere binnentemperaturen",
        "Vermindering van geluidsoverlast",
        "Potentiële waardevermeerdering van uw woning",
      ];
    case "ventilation":
      return [
        "Vermindert vocht en voorkomt schimmelgroei",
        "Filtert buitenlucht, vermindert allergenen en vervuilende stoffen",
        "Kan helpen bij koeling in zomermaanden",
      ];
    case "living_room_windows":
    case "bedroom_windows":
      return [
        "Verbeterde isolatie en energie-efficiëntie",
        "Verhoogd comfort met minder tocht en koude plekken",
        "UV-bescherming voor uw meubilair",
      ];
    case "wall_insulation":
      return [
        "Verbeterd comfort met constantere binnentemperaturen",
        "Vermindering van geluidsoverlast",
        "Potentiële waardevermeerdering van uw woning",
      ];
    case "instalation":
    case "shower":
      return [
        "Vermindert energieverbruik voor waterverwarming",
        "Kan worden geïnstalleerd met minimale verstoring",
        "Onderhoudsvrije werking",
      ];
    default:
      return [];
  }
};

const CompleteOverzichtPage: React.FC<CompleteOverzichtPageProps> = ({
  data,
}) => {
  const measureNames = [
    "solar_panels",
    "floor_insulation",
    "living_room_windows",
    "bedroom_windows",
    "wall_insulation",
    "instalation",
    "shower",
    "ventilation",
  ];

  const measureTitles: { [key: string]: string } = {
    solar_panels: "Aantal zonnepanelen",
    floor_insulation: "Vloerisolatie",
    living_room_windows: "Glas woonkamer",
    bedroom_windows: "Glas slaapkamers",
    wall_insulation: "Gevelisolatie",
    instalation: "CV-ketel of warmtepomp",
    shower: "Huidige douche WTW",
    ventilation: "Ventilatie",
  };

  const nonZeroMeasures = measureNames.filter(
    (measure) => data.measures[measure]?.investment > 0,
  );

  const renderRecommendation = (measure: string) => {
    const measureData = data.measures[measure];
    const icon = getIconForMeasure(measure);
    const additionalBenefits = getAdditionalBenefits(measure);

    return (
      <RecommendationItem
        key={measure}
        icon={icon}
        title={measureTitles[measure]}
        description={`Wij raden aan om ${measureData.after.desc}`}
        co2Reduction={measureData.co2_reduce}
        monthlySavings={measureData.saving}
        installationCost={measureData.investment}
        paybackPeriod={
          data.financial.total_investment / data.financial.total_saving
        }
        additionalBenefits={additionalBenefits}
      />
    );
  };

  return (
    <>
      <Page size="A4" style={styles.page}>
        <Text style={styles.pageTitle}>Aanbevelingen</Text>
        <Text style={styles.pageText}>
          Op basis van de huidige situatie zijn hier specifieke maatregelen voor
          een lager energieverbruik en een betere duurzaamheid
        </Text>

        {nonZeroMeasures.slice(0, 2).map(renderRecommendation)}

        <View style={styles.footer}>
          <Text style={styles.footerText}>Eigendom van Altum AI | © 2024</Text>
          <Image src={altumLogo} style={styles.footerLogo} />
        </View>
      </Page>

      {nonZeroMeasures.length > 2 && (
        <Page size="A4" style={styles.page}>
          {nonZeroMeasures.slice(2, 4).map(renderRecommendation)}

          <View style={styles.footer}>
            <Text style={styles.footerText}>
              Eigendom van Altum AI | © 2024
            </Text>
            <Image src={altumLogo} style={styles.footerLogo} />
          </View>
        </Page>
      )}

      {nonZeroMeasures.length > 4 && (
        <Page size="A4" style={styles.page}>
          {nonZeroMeasures.slice(4).map(renderRecommendation)}

          <View style={styles.footer}>
            <Text style={styles.footerText}>
              Eigendom van Altum AI | © 2024
            </Text>
            <Image src={altumLogo} style={styles.footerLogo} />
          </View>
        </Page>
      )}
    </>
  );
};

export default CompleteOverzichtPage;
