import React, { useState } from "react";
import { FormProvider } from "../../components/FormContext";
import EnergyLabelContainer from "../components/EnergyLabelContainer";
import EnergyLabelResult from "./EnergyLabelResult";

const EnergyLabelResultPage = () => {
  const [page] = useState(3);

  return (
    <FormProvider>
      <EnergyLabelContainer page={page}>
        <EnergyLabelResult />
      </EnergyLabelContainer>
    </FormProvider>
  );
};

export default EnergyLabelResultPage;
